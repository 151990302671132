<template>
  <div>
    <hello-world />
    <our-services/>
    <why-to-work-with-us/>
    <our-work/>
    <markiting-sec/>
    <explore-more/>
  </div>
</template>

<script>
import ExploreMore from '@/components/ExploreMore.vue'
import HelloWorld from '../components/HelloWorld'
import OurWork from '@/components/OurWork.vue'
import OurServices from '@/components/OurServices.vue'
import WhyToWorkWithUs from '@/components/WhyToWorkWithUs.vue'
import MarkitingSec from '@/components/MarkitingSec.vue'

export default {
  name: 'Home-View',

  components: {
    HelloWorld,
    OurServices,
    WhyToWorkWithUs,
    ExploreMore,
    OurWork,
    MarkitingSec
  }
}
</script>
