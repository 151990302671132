<template>
  <div class="mb-16">
    <v-row class="d-flex justify-center align-center mt-10">
      <h1 class="arabic mr-n1 mt-16 mb-n5 secondary1 text-center" style="font-size: 40px;" data-aos="fade-down" data-aos-easing="ease-out-cubic"
       data-aos-duration="900">استكشف المزيد في راقي</h1>
    </v-row>
  <v-row class="d-flex justify-center align-center mt-16" width="325px">
    <v-col class="d-flex justify-center align-center align-content-center flex-column px-0"  md="3" cols="12" sm="12" data-aos="fade-up" data-aos-easing="ease-out-cubic"
     data-aos-duration="800" data-aos-delay="100">
      <div class="d-flex justify-center align-center flex-column">
        <v-img alt="blog" class="mt-2" src="../assets/Blogger.svg"  :style="{ 'height': $vuetify.breakpoint.smAndDown ? '125px' : '200px', 'width' : $vuetify.breakpoint.smAndDown ? '125px' : '200px' }"  style="text-shadow: 5px 5px 30px #00d5ff;"></v-img>
        <p class="arabic mt-6 secondary1" :style="{'font-size' : $vuetify.breakpoint.mdAndUp ? '25px' : '23px' }">المدونة</p>
        <h4 class="arabic px-14 text-center"
          style="color: #014856cd; font-size: medium;">تابع أحدث رؤياتنا في مجالات عملنا المختلفة</h4>
        <v-btn color="#005F9E" alt="blog" href="/blog" outlined class="arabic sec-btn mt-5" height="50px" width="210px"  :style="{ 'width' : $vuetify.breakpoint.smAndDown ? '125px' : '210px', 'font-size' : $vuetify.breakpoint.smAndDown ? '13px' : '17px' }"
         style="border-radius: 50px; letter-spacing: 0; opacity: 92%;
         transition: all .4s normal linear !important;">المدونة<v-icon class="mr-2">mdi-arrow-left</v-icon></v-btn>
      </div>
       <v-divider v-if="$vuetify.breakpoint.smAndDown" width="250px" class="mt-5"></v-divider>
      </v-col>
      <v-col class="d-flex justify-center align-center flex-column px-0 align-content-center"  md="3" cols="12" sm="12" data-aos="fade-up" data-aos-easing="ease-out-cubic"
     data-aos-duration="800" data-aos-delay="50">
      <div class="d-flex justify-center algin-center flex-column align-center">
        <v-img alt="contact us" class="mt-n5" src="../assets/contact.svg" :style="{ 'height': $vuetify.breakpoint.smAndDown ? '145px' : '230px', 'width' : $vuetify.breakpoint.smAndDown ? '150px' : '270px' }"></v-img>
        <p class="arabic mt-4 secondary1" style="font-size: 25px;">تواصل معنا</p>
        <h4 class="arabic text-center" style="color: #014856cd;">متواجدون على مدار الساعة لخدمتكم <br>لا تتردد بتقديم استشارة الآن!</h4>
        <v-row>
        <v-btn color="#005f9e" alt="contact us" href="/contact-us"  class="white--text arabic sec-gradient-btn mt-7 mr-1" height="50px" width="210px"
         style="border-radius: 50px; font-size: 17px; letter-spacing: 0; opacity: 92%;
         transition: all .4s normal linear !important;">تواصل معنا<v-icon class="mr-2">mdi-email-fast-outline</v-icon></v-btn>
         <v-icon class="mr-2 ml-n10 animate-flicker" size="15px" color="green" style="opacity: 80% ">mdi-circle</v-icon>
        </v-row>
      </div>
      <v-divider v-if="$vuetify.breakpoint.smAndDown" width="250px" class="mt-5"></v-divider>
  </v-col>
    <v-col class="d-flex justify-center align-center flex-column px-0 align-content-center" md="3" cols="12" sm="12" data-aos="fade-up" data-aos-easing="ease-out-cubic"
     data-aos-duration="800" data-aos-delay="100">
      <div class="d-flex justify-center align-center flex-column" >
        <v-img  alt="portfolio" class="mt-2 mr-n5" src="../assets/Works.svg" width="220px" height="220px" :style="{ 'height': $vuetify.breakpoint.smAndDown ? '150px' : '220px', 'width' : $vuetify.breakpoint.smAndDown ? '150px' : '220px' }"></v-img>
        <p class="arabic mt-2 secondary1" style="font-size: 25px;">معرض الأعمال</p>
        <h4 class="arabic px-14 text-center" style="color: #014856cd;">تعرف على أعمالنا كما نتطلع لإضافة مشروعك الجديد الى مسيرتنا</h4>
        <v-btn color="#005f9e" alt="portfolio" href="/portfolio"  outlined class="arabic sec-btn mt-5" height="50px" width="210px"
         style="border-radius: 50px; font-size: 17px; letter-spacing: 0; opacity: 92%; color: #005f9e;
         transition: all .4s normal linear !important;">معرض الأعمال<v-icon class="mr-2">mdi-arrow-left</v-icon></v-btn>
      </div>
    </v-col>
  </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style>
.sec-gradient-btn:hover{
  background: rgb(1,217,240);
background: linear-gradient(120deg, rgba(1,217,240,1) 0%, rgba(0,93,164,0.3086484593837535) 90%);
box-shadow: 0.5px 5px 30px #0197b576;
-webkit-transition: all .0.1s normal linear !important;
transition: all .0.1s normal linear !important;
}
.sec-btn:hover{
background: #005f9e;
background: #005f9e;
box-shadow: 0.5px 5px 30px #044fa576;
-webkit-transition: all .0.1s normal linear !important;
transition: all .0.1s normal linear !important;
border-color: #005F9E;
color: white !important;
}
.secondary1{
  color: #005F9E;
}
</style>
