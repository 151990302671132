<template>
  <v-app>
    <!-- Mobile app bar -->
    <v-app-bar
      color="white"
      flat
      style="z-index: 10000;"
    >
    <div class="d-flex flex-row justify-space-between" style="width: 100%;">
      <div class="d-flex flex-row algin-center">
        <v-img alt="RAQI Logo"
              class="shrink ml-2 mr-2 rotate"
              contain
              src="./assets/Raqi_logo.svg"
              transition="scale-transition"
              width="45">
            </v-img>
            <a href="/" style="text-decoration: none;">
              <p class="arabic ma-0 pa-0 mx-2 mb-2" style="font-size: 30px; letter-spacing: 1px; color: #005F9E;" >راقي</p>
            </a>
      </div>
      <v-app-bar-nav-icon @click="drawer = true" class="mt-1"></v-app-bar-nav-icon>
    </div>
    </v-app-bar>
    <!-- MOBILE  -->
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      style="z-index: 10002;"
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <a href="/" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;"> الرئيسية</p>
          </a>
          <v-divider></v-divider>
          <a href="/about" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;"> من نحن</p>
          </a>
          <v-divider></v-divider>
          <btn @click="goToSpecificComponentInHome" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;"> خدماتنا </p>
          </btn>
          <v-divider></v-divider>
          <a href="/portfolio" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;">أعمالنا</p>
          </a>
          <v-divider></v-divider>
          <a href="/blog" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;">   مدونتنا</p>
          </a>
          <v-divider></v-divider>
          <a href="/contact-us" style="text-decoration: none;" >
            <p class="arabic ma-3" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px; color: #005F9E;"> تواصل معنا</p>
          </a>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- alert on mobile (to view the website on desktop) -->
    <!-- <div class="d-flex justify-center align-center mt-11" style="height: 100px;" v-if="$vuetify.breakpoint.smAndDown && showCard" >
      <v-card
        style="width: 350px !important;"
        shaped
        height="50"
        class="my-16 arabic-text text-center flex-row d-flex align-center light-blue lighten-5"
      >
       <p class="mt-2" style="font-size: 15px;">يرجى تصفح الموقع على جهاز الكومبيوتر لتجربة أفضل</p>
          <v-btn
            color="#005F9E"
            @click="hideCard"
            text
            v-bind="attrs"
            class="align-center mx-1"
          >
           <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card>
    </div> -->
    <!-- ////////////////////////////////////////////////// -->
    <v-app-bar
    v-if="!$vuetify.breakpoint.smAndDown"
    app
    flat
    color="white"
    rounded="lg "
    elevate-on-scroll
    elevation="7"
    style="z-index: 10055;"
    class="hidden-sm-and-down"
  >
  <div class="d-flex align-center ml-10">
        <v-img
          alt="RAQI Logo"
          class="shrink ml-2 mr-2 rotate"
          contain
          src="./assets/Raqi_logo.svg"
          transition="scale-transition"
          width="55"
        />
         <a href="/" alt="home" style="text-decoration: none;">
          <p class="arabic mb-3 mr-2" style="font-size: 39px; letter-spacing: 1px; color: #005F9E;" >راقي</p>
        </a>
      </div>
      <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          href="/"
          alt="home"
          style="margin-right: 10%; height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">الرئيسية</span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          :to="{name:'home' , hash:'#ourServices'}"
          alt="our service"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">خدماتنا</span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          href="/about"
          alt="About page"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">من نحن</span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          href='/portfolio'
          alt="portfolio"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">أعمالنا</span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          href="/blog"
          alt="blog"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">مدونتنا</span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
        <v-hover v-slot="{ hover }" style="">
      <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          href="/contact-us"
          alt="contact us"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">تواصل معنا <v-icon class="mr-1" v-if="hover">mdi-email-fast</v-icon>
              <v-icon class="mr-1" v-if="!hover">mdi-email-fast-outline</v-icon>
            </span>
              <v-slide-y-transition>
                <div v-if="hover" class="blue" style="height: 3px; width: 100%; display: block; position: absolute;"></div>
              </v-slide-y-transition>
          </div>
        </v-btn>
        </v-hover>
      <v-divider vertical class="mt-2" style="background-color: rgb(40, 174, 174); min-height: 70%; max-height: 70%; height: 70% !important;"></v-divider>
        <v-hover v-slot="{ hover }" style="">
        <v-btn
          text
          :color="hover ? 'blue' : '#005F9E'"
          alt="الأكثر طلبا"
          @mouseover="showList = true" @mouseleave="showList = false"
          style="height: 0px">
          <div>
            <span class="arabic pb-1" style="letter-spacing: 0px; display: block; font-weight: 100; font-size: 16px;">الأكثر طلباً<v-icon class="mr-1">mdi-trending-up</v-icon>
            </span>
              <v-scroll-y-transition>
                <v-icon v-if="hover" style="right: 30px; position: absolute; height: 0px;">mdi-triangle-small-down</v-icon>
              </v-scroll-y-transition>
              <v-scroll-y-transition>
                <v-card v-if="showList" class="hover-card mx-auto" min-width="270px" elevation="7" outlined>
                  <v-col align="center">
                    <v-hover v-slot="{ hover }" style="">
                      <v-btn href="/apps-dev" text :color="hover ? 'blue' : '#005F9E'" class="arabic my-7" style="width: 100%; margin: auto; height: 0px; font-size: 15px; letter-spacing: 0px;"><v-icon style="position: absolute; right: -6px;">mdi-cellphone-settings</v-icon>تطوير التطبيقات الحصرية</v-btn>
                    </v-hover>
                    <v-divider></v-divider>
                    <v-hover v-slot="{ hover }" style="">
                      <v-btn href="/online-stores" text :color="hover ? 'blue' : '#005F9E'" class="arabic my-7" style="width: 100%; margin: auto; height: 0px; font-size: 15px; letter-spacing: 0px;"><v-icon style="position: absolute; right: -5px;">mdi-shopping-outline</v-icon>إنشاء المتاجر الالكترونية</v-btn>
                    </v-hover>
                    <v-divider></v-divider>
                    <v-hover v-slot="{ hover }" style="">
                      <v-btn href="/automation-dev" text :color="hover ? 'blue' : '#005F9E'" class="arabic my-7" style="width: 100%; margin: auto; height: 0px; font-size: 15px; letter-spacing: 0px;"><v-icon style="position: absolute; right: -4px;">mdi-robot-outline</v-icon>أتمتة الاعمال الروتينية</v-btn>
                    </v-hover>
                  </v-col>
                </v-card>
              </v-scroll-y-transition>
          </div>
        </v-btn>
        </v-hover>
      </v-app-bar>

    <v-main class=" pa-0">
      <router-view>

        </router-view>
        <v-btn icon x-large v-scroll="onScroll" v-show="isVisible" @click="toTop" elevation="24" class="top" style="background-color: #005F9E;">
          <v-icon color="white">mdi-chevron-double-up</v-icon>
        </v-btn>
        <v-btn icon x-large  href="https://wa.me/message/3HFDB3YC3LEBF1" target="_blank" elevation="24" class="WhatsAppFloating" style="background-color: #25D366;">
          <img
                height="33px"
                alt="WhatsApp"
                src="./assets/WhatsApp white.svg"
              />
        </v-btn>
        <v-btn icon large elevation="5" @click="call_dialog=true" class="PhoneFloating" style="background-color: aliceblue;">
          <v-icon size="large" color="#005F9E">mdi-phone-in-talk</v-icon>
        </v-btn>
    </v-main>
    <apps-dev-parallax/>
    <v-footer cols="12" md="12" style="background-color: white;" flat rounded padless class="d-flex align-end">
  <v-card flat tile color="aliceblue" class="d-flex flex-column" :class="{'flex-row': $vuetify.breakpoint.smAndDown , 'flex-column': $vuetify.breakpoint}" width="100%" min-height="100%" >
    <div :class="{'flex-column': $vuetify.breakpoint.smAndDown , 'flex-row': $vuetify.breakpoint.mdAndUp}" class="d-flex algin-center justify-space-around my-3" >
      <v-col md="3" cols="12" sm="12" align-self="center" class="title-font arabic text-center" style="color: #005F9F">
        شركة راقي جميع الحقوق محفوظة
        &copy; {{ new Date().getFullYear() }}
      </v-col>
      <v-col md="5" sm="12" cols="12" style="color: #005F9F" class="arabic text-center" align-self="center">
      <v-row class="d-flex justify-center align-center mb-1">
          <v-btn icon class="" href="https://t.me/raqiceo">
            <img class="shrink" alt="تيليغرام" height="23px" src="./assets/telegram_icon_136124.svg">
          </v-btn>
          <v-btn icon class="" href="https://www.facebook.com/profile.php?id=100095227630900">
            <img class="shrink" alt="فيسبوك" height="24px" src="./assets/Facebook.svg">
          </v-btn>
          <v-btn icon class="" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFnPaHjTZhJ3AAAAYmrUpeAFI-6U3reiDsSYgq10127tcmVhXO3J1T4rr_FcQSHZRlDnilbVe-NyHFL21Y0Eca5Cp8UbzZcXg9yHMamaiznZfAHce2_0ZtVvPZNqsLAxOcGOz4=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fraqi-co%2F">
            <img class="shrink" alt="لينكدإين" height="24px" src="./assets/LinkedIn.svg">
          </v-btn>
          <v-btn icon class="" href="https://wa.me/message/3HFDB3YC3LEBF1">
            <img class="shrink" alt="واتساب" height="23px" src="./assets/WhatsApp.svg">
          </v-btn>
      </v-row>
         <p class="text-center"  v-if="$vuetify.breakpoint.mdAndUp">بواسطة فريق راقي للتطوير مع كل الاحترام والتقدير</p>
      </v-col>
      <v-col md="3" sm="12" cols="12" style="color: #005F9E; text-decoration: none;" class="arabic text-center" align-self="center">
        <a href="/privacy-policy" style="text-decoration: none;">سياسة الخصوصية والأمان <v-icon color="#1976D2">mdi-shield-check</v-icon></a>
      </v-col>
    </div>
  </v-card>
</v-footer>
<v-dialog
      v-model="call_dialog"
      max-width="400px"
      class="arabic-text"
    >
      <v-card class="d-flex justify-center flex-column align-center arabic-text" style="border-radius: 15px;">
        <p class="arabic primary1 mt-3" style="color: #005F9E;">اتصل بنا الآن على</p>
        <p class="arabic" style="font-size: large; color: #153D4A;">9952 534 55 966+ <v-icon class="mr-1" size="large" color="#153D4A">mdi-phone-in-talk</v-icon></p>

      <v-btn v-if="$vuetify.breakpoint.smAndDown"
      color="primary"
      class="mb-2"
      style="letter-spacing: 0.02cap;"
      :href="'tel:+966555349952'"
    >
      اتصل بالرقم <v-icon class="mr-2" size="medium">mdi-phone</v-icon>
    </v-btn>
        <v-btn
      color="primary"
      @click="copyToClipboard"
      class="arabic-text mb-1"
      style="letter-spacing: 0.02cap;"
    >
      انسخ الرقم <v-icon class="mr-2" size="medium">mdi-content-copy</v-icon>
    </v-btn>
  <v-snackbar
    v-model="copied"
      timeout="1000"
      color="success"
      rounded="pill"
    >
      <p class="arabic text-center pa-0 ma-0">تم نسخ الرقم بنجاح<v-icon class="mr-2" size="medium">mdi-check</v-icon></p>
    </v-snackbar>
        <v-card-actions>
          <v-btn
            color="black darken-1"
            text
            style="letter-spacing: 0;"
            @click="call_dialog = false"
          >
            إغلاق
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</v-app>
</template>
<script>
import AppsDevParallax from './components/PreFooter.vue'

export default {
  components: { AppsDevParallax },
  name: 'App',
  data: () => ({
    showList: false,
    drawer: false,
    isVisible: false,
    call_dialog: false,
    group: null,
    showCard: true,
    textToCopy: '+966555349952',
    copied: false
  }),
  // ...mapActions([fetchArticles])
  mounted () {
    // this.$store.dispatch('fetchArticles')
    // return this.$store.state.articles
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleScroll)
    this.$store.dispatch('fetchArticles')
    return this.$store.state.articles
  },
  methods: {
    navigateTo (route) {
      // Code to navigate to the specified route using Vue Router
      this.$router.push(route)
      this.mobileDrawer = false // Close the mobile drawer after navigation
    },
    onScroll () {
      this.isVisible = window.scrollY > 50
    },
    toTop () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    toBottom () {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    },
    copyToClipboard () {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.textToCopy)
          .then(() => {
            this.copied = true
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err)
          })
      } else {
        // Fallback for browsers that don't support clipboard API
        const textArea = document.createElement('textarea')
        textArea.value = this.textToCopy
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)
        this.copied = true
      }
    },
    goToSpecificComponentInHome () {
      // Check if the current route is already the Home route
      if (this.$route.path !== '/') {
        // Programmatic navigation to specific component in Home view
        this.$router.push({ path: '/', hash: '#ourServices' })
      } else {
        // If already on the Home route, manually scroll to the desired component
        this.scrollToOurServices()
      }
    },
    scrollToOurServices () {
      // Function to scroll to the #ourServices component
      const element = document.getElementById('ourServices')
      this.drawer = false
      // const element1 = document.getElementById('close')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    hideCard () {
      this.showCard = false
    }
  },
  created () {
    this.$vuetify.rtl = true
    console.log(this.$store.getters.getArticles)
  }
  // computed: {
  //   articles () {
  //     return this.$store.state.articles
  //   }
  // }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Alexandria&family=Concert+One&family=El+Messiri&family=Lalezar&family=Marhey&family=Montserrat:wght@500&family=PT+Sans+Narrow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;800&display=swap');
body{
  overflow-x: hidden;
}
.top{
  position: fixed;
  bottom: 30px;
  left: 40px;
  z-index: 10000;
}
.top2{
  position: fixed;
  bottom: 10%;
  left: 10%;
  z-index: 10000;
}
.WhatsAppFloating{
  position: fixed;
  bottom: 30px;
  right: 25px;
  z-index: 10000;
  transition: transform 0.5s ease;
  animation: scaleAnimation 2s infinite;
}
.PhoneFloating{
  position: fixed;
  bottom: 95px;
  right: 30px;
  z-index: 10000;
}
.arabic{
  font-family: 'Cairo', sans-serif !important;
  font-weight: 800 !important;
  line-height: inherit !important;
}
.arabic-text{
  font-family: 'Cairo', sans-serif !important;
  font-weight: 400;
  line-height: inherit !important;
}
.rotate {
  -webkit-animation: rotate 10s normal linear infinite;
  animation: rotate 10s normal linear infinite;
}
.website-background-color {
  background-color: white;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.hover-card{
  position: absolute;
  top: 125%;
  left: -95px;
}
.v-btn--icon.v-size--default .v-icon, .v-btn--fab.v-size--default .v-icon{
  height: 35px;
    font-size: 35px;
    width: 35px;
    color: #005F9E;
}
</style>
