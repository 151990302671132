<template>
 <div :class="{'mt-n10':$vuetify.breakpoint.mdAndUp}" id="Home" style="height: 100vh;"
  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
  <!-- alert on mobile (to view the website on desktop) -->
  <div class="d-flex justify-center align-center mt-2 mb-n10" style="height: 100px;" v-if="$vuetify.breakpoint.smAndDown && showCard" >
      <v-card
        style="width: 350px !important;"
        shaped
        height="60"
        class="text-center flex-row d-flex align-center light-blue lighten-5 px-5"
      >
       <p class="arabic mt-2 mr-2" style="font-size: 15px; opacity: 75%;">يمكنك تصفح الموقع على جهاز الكومبيوتر لتجربة أفضل.</p>
          <v-btn
            color="black"
            style="opacity: 60%;"
            @click="hideCard"
            text
            v-bind="attrs"
            class="align-center mx-1"
          >
           <v-icon>mdi-close</v-icon>
          </v-btn>
      </v-card>
    </div>
    <!-- ////////////////////////////////////////////////// -->
     <v-row
    class="d-flex justify-space-around transparent flat mt-10 align-center  flex-row flex-wrap" :class="{'flex-wrap mx-3': $vuetify.breakpoint.smAndDown , 'mx-10':$vuetify.breakpoint.mdAndUp}"
  >
    <v-col class="" :class="{'px-12':$vuetify.breakpoint.mdAndUp}" cols="12" sm="12" md="6" style="z-index: 10;" transition="scale-transition">
             <div :class="{'d-flex flex-column justify-center align-center':$vuetify.breakpoint.smAndDown}">
               <p class="arabic mb-16 mt-n1"
               :class="{'text-center':$vuetify.breakpoint.smAndDown}"
                :style="{'font-size': $vuetify.breakpoint.smAndDown? '25px' : '45px'}"
                  style="text-shadow: 1px 1px 200px #01c5ec9a; box-shadow: 50px #01b5d9a5; color: #153D4A;">إبدأ <span style="color: #005F9E;">مشروعك </span>الآن مع فريق <span style="color: #005F9E;">راقي</span> لتطوير البرمجيات</p>
                  <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }">
               <v-btn target="_blank" href="https://wa.me/message/3HFDB3YC3LEBF1" color="#005F9E" alt="ask for help" class="white--text arabic gradient-btn" v-bind="attrs" v-on="on" :style="{'height': $vuetify.breakpoint.smAndDown? '40px':'60px' , 'width': $vuetify.breakpoint.smAndDown? '270px':'270px', 'font-size': $vuetify.breakpoint.smAndDown? '18px':'20px'}" style="border-radius: 50px; letter-spacing: 0; opacity: 92%; transition: all .4s normal linear !important;">  اطلب استشارة
                  <v-icon class="mr-4" v-if="hover" size="35px">mdi-lightbulb-on</v-icon>
                  <v-icon class="mr-4" v-if="!hover" size="30px">mdi-lightbulb-question-outline</v-icon>
                           </v-btn>
                         </v-hover>
                         </template>
                          <span class="arabic" style="font-size: small; font-weight: 500;">تواصل معنا مباشرةً <v-icon class="mr-1 mt-n1" color="white" small style="rotate: 180deg">mdi-send</v-icon></span>
                        </v-tooltip>
                         <div>
                           <a target="_blank" href="https://t.me/raqiceo" style="text-decoration: none;" alt="contact-telegram">
                             <v-btn id="no-background-hover" class="arabic mt-2"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '15px' : '20px' , 'margin-right' :$vuetify.breakpoint.smAndDown? '' : '45px' }"  style="height: 0px; display: block; opacity: 80%; letter-spacing: 0.2px; color: rgb(20, 120, 20);">
                              متاحين حالياً
                              <v-icon :small="$vuetify.breakpoint.smAndDown" x-small class="mr-2 mt-1 animate-flicker" color="rgb(20, 120, 20)">
                                mdi-circle
                              </v-icon>
                            </v-btn>
                           </a>
                         </div>
             </div>
          </v-col>
    <v-col  cols="12" sm="12" md="6" style="z-index: 10; justify-content: center;" class="d-flex align-center justify-center" transition="scale-transition">
             <v-hover v-slot="{ hover }" style="">
             <img alt="home image" class="mt-10" :class="{'mt-15 mb-n10 ml-16 mr-n16':$vuetify.breakpoint.mdAndUp}" src="../assets/pastel light.svg" style="transition: all .2s normal linear;" :style="{ 'scale' : hover ? '1.02' : '1', 'width': $vuetify.breakpoint.smAndDown? '300px':'650px' }">
            </v-hover>
    </v-col>
  </v-row>
     <div class="wrap-banner mb-n16" style="margin-top: -270px;" >
    <vue-particles
      color="#005F9E"
      :particleOpacity="0.5"
      :particlesNumber="100"
      shapeType="triangle"
      :particleSize="7"
      linesColor="#86B197"
      :linesWidth="1"
      :lineLinked="true"
      :lineOpacity="0.5"
      :linesDistance="150"
      :moveSpeed="0.7"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="repulse"
    >
    </vue-particles>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    showCard: true
  }),
  methods: {
    navigateToSection (route, sectionId) {
    // Navigate to the specified route
      this.$router.push(route)

      // Wait for the route to be fully loaded and then scroll to the target section
      this.$nextTick(() => {
        const targetSection = this.$refs.sectionId // Replace 'section1' with the ID of your target section
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' })
        }
      })
    },
    hideCard () {
      this.showCard = false
    }
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=Josefin+Sans&family=Montserrat:wght@600&family=Tajawal&family=Teko:wght@300&display=swap');
.gradient-btn:hover{
  background: rgb(1,217,240);
background: linear-gradient(117deg, rgba(1,217,240,1) 0%, rgba(0,93,164,0.3086484593837535) 65%);
box-shadow: 0.5px 5px 30px #0197b576;
-webkit-transition: all .0.1s normal linear !important;
transition: all .0.1s normal linear !important;
}
.wrap-banner {
     position: relative;
     top: -170px;
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;
}
#particles-js{
  width:100%;
  height: 500px;
  z-index: 1;
}
.rotate1 {
  -webkit-animation: rotate 40s normal linear infinite;
  animation: rotate 40s normal linear infinite;
}
#no-background-hover::before {
   background-color: transparent !important;
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 2s infinite;
   -moz-animation: flickerAnimation 2s infinite;
   -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}
</style>
