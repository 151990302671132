<template>
  <div class="mt-16 mb-16" data-aos="fade-up" data-aos-offset="100" data-aos-duration="1000">
    <v-img src="../assets/4k tech copy.jpg" :style="{'height' : $vuetify.breakpoint.mdAndUp ? '450px':'100%'}" width="100%">
      <v-row class="d-flex justify-center align-center">
    <v-col class="d-flex justify-center align-center flex-column mr-1" md="4" cols="12" :class="{'mt-5' : $vuetify.breakpoint.smAndDown}">
      <v-icon :style="{'font-size' : $vuetify.breakpoint.mdAndUp ? '110px':'80px'}" style="color: aliceblue;">mdi-candy-outline</v-icon>
      <!-- <img src="../assets/candy.svg" width="110px" alt=""> -->
      <p class="arabic mt-1" style="color: aliceblue; text-align: center;" :style="{'font-size' : $vuetify.breakpoint.mdAndUp ? '45px':'x-large'}">مميزات العمل<br> مع شركة راقي؟</p>
    </v-col>
    <v-col class="d-flex justify-center align-center flex-column mt-3" md="7" cols="12">
      <v-carousel touch cycle interval="4000" height="400" style="width: 700px;" hide-delimiter-background>
        <v-carousel-item>
      <v-col class="arabic d-flex justify-center align-center flex-column">
        <v-img alt="competitive price" class="mt-6" src="../assets/good price.svg" width="22%" height="20%" style=""></v-img>
        <p class="arabic mt-5" style="color:aliceblue; font-size: x-large;">أسعار تنافسية</p>
        <p class="arabic-text px-16" style="text-align: center; color:aliceblue; font-size: medium;">
          نقدم أسعارًا منافسة ومنطقية تساعد عملائنا في الحصول على أفضل قيمة لاستثمارهم.</p>
      </v-col>
    </v-carousel-item>
        <v-carousel-item>
      <v-col class="arabic d-flex justify-center align-center flex-column">
        <v-img alt="speed" class="mt-2" src="../assets/fast work.svg" width="35%" height="28%" style=""></v-img>
        <p class="arabic mt-5" style="color:aliceblue; font-size: x-large;">السرعة والكفاءة</p>
        <p class="arabic-text px-16" style="text-align: center; color:aliceblue; font-size: medium;">
نعتبر الانتهاء من المشاريع بسرعة وبكفاءة عالية أولوية، مع الحفاظ على جودة ممتازة.</p>
      </v-col>
    </v-carousel-item>
    <v-carousel-item>
      <v-col class="arabic d-flex justify-center align-center flex-column">
        <v-img alt="tecnology" class="mt-2" src="../assets/creativity.svg" width="28%" height="20%" style=""></v-img>
        <p class="arabic mt-5" style="color:aliceblue; font-size: x-large;">الابتكار والتكنولوجيا</p>
        <p class="arabic-text px-16" style="text-align: center; color:aliceblue; font-size: medium;">
          نستخدم أحدث التقنيات والأدوات لتقديم حلول مبتكرة وفعّالة تلبي طموحات واحتياجات عملائنا.</p>
      </v-col>
    </v-carousel-item>
    <v-carousel-item>
      <v-col class="arabic d-flex justify-center align-center flex-column">
        <v-img alt="availability" class="mt-10" src="../assets/available.svg" width="26%" height="20%" style=""></v-img>
        <p class="arabic mt-2" style="color:aliceblue; font-size: x-large;">توافر على مدار الساعة</p>
        <p class="arabic-text px-16" style="text-align: center; color:aliceblue; font-size: medium;">
          نحن هنا لخدمتك على مدار الساعة، جاهزين للتواصل في أي وقت تحتاج فيه تواصل معنا بسهولة واستمتع بتجربة اتصال خالية من المتاعب.</p>
      </v-col>
    </v-carousel-item>
  </v-carousel>
    </v-col>
    </v-row>
    </v-img>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
