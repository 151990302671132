<template>
  <div class="ourWork-bg" id="ourServices" >
    <v-row class="d-flex mx-8 algin-center justify-center" :class="{ ' flex-row': $vuetify.breakpoint.mdAndUp, ' flex-column': $vuetify.breakpoint.smAndDown }" align="center" justify="center" data-aos="fade-down" data-aos-duration="300">
        <v-col md="3" class="arabic mt-8" :class="{'mr-n16': $vuetify.breakpoint.mdAndUp }">
          <h1 class="text-center mt-n4" style=" color: #00609E;" id="close">خدماتنا</h1>
        </v-col>
        <v-col md="7">
          <p class="arabic-text mt-8" :class="{'text-center': $vuetify.breakpoint.smAndDown }" style="font-size: 20px;">
            تشمل خدماتنا تطوير تطبيقات الويب والهاتف المحمول، تصميم وتطوير قواعد البيانات، إدارة المشاريع التقنية، والاستشارات التقنية. يسعد فريقنا المتخصص بالبرمجة بتلبية احتياجاتك وضمان تقديم خدمات عالية الجودة وفقًا للمعايير الصناعية. اتصل بنا اليوم للاستفادة من خبرتنا في مجال تكنولوجيا المعلومات وضمان نجاح مشروعك الإلكتروني.
          </p>
        </v-col>
        <v-col md="2" class="mt-8 d-flex justify-center" align-self="center">
          <v-btn text outlined rounded style="letter-spacing: 0.5px;" href="/contact-us" class="arabic" color="#00609E">
     اطلب خدمتك الاّن &nbsp;<v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
    </v-row>
      <div class="ma-0" :class="{'d-flex flex-row justify-center algin-center': $vuetify.breakpoint.mdAndUp}">
        <v-col md="2" sm="12" class="d-flex justify-center algin-center" style="align-items: center;" data-aos="fade-left" data-aos-duration="300">
       <v-tabs v-model="tab" background-color="transparent" :vertical="$vuetify.breakpoint.mdAndUp"  center-active show-arrows  icons-and-text color="#005F9E" class="d-flex align-center justify-center" :class="{'mt-7': $vuetify.breakpoint.smAndDown}">
              <v-tab v-for="item in items" :key="item.tab" class="my-5">
                <p class="arabic" style="letter-spacing: .5px;" :style="{'font-size': $vuetify.breakpoint.smAndDown ? '12px' : ''}">{{ item.tab }}</p>
                <v-icon>{{ item.icon }}</v-icon>
              </v-tab>
            </v-tabs>
            </v-col>
          <v-col md="10" sm="12">
            <v-tabs-items :class="{ 'mt-5': $vuetify.breakpoint.mdAndUp }" v-model="tab" :style="{'height': $vuetify.breakpoint.smAndDown? '':'600px'}" data-aos="fade-right" data-aos-duration="800">
               <!-- تطبيقات الجوال -->
              <v-tab-item>
                <v-row class="d-flex justify-center algin-center flex-row  mt-16" align-content="center" justify="center">
                  <v-col class="d-flex justify-center" md="5"  sm="12">
                    <v-hover v-slot="{ hover }">
                      <v-card  :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: all .3s normal linear; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center;">
                              <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >تطبيقات سوشال ميديا</p>
                              <p class="arabic mt-5 mb-7 text-center"  v-if="isHover || hover" style="font-size: medium; align-self: center; transition: all .3s normal linear; color: #005f9e;" >تطبيقات سوشال ميديا مثل :</p>
                              <v-row align-content="center" justify="center" v-if="hover" class="pa-0 ma-0" >
                                <v-icon class="mx-8" color="#005f9e" size="x-large">mdi-instagram</v-icon>
                                <v-icon class="mx-8" color="#005f9e" size="x-large">mdi-music-box-outline</v-icon>
                                <v-icon class="mx-8" color="#005f9e" size="x-large">mdi-snapchat</v-icon>
                              </v-row>
                              <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                                <p class="arabic text-center" :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}" style="color: #005f9e;"><span style="display: none;">تطبيق مثل</span> انستغرام <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span> تيك توك <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span> سناب شات </p>
                              </v-row>
                            </v-col>
                            <!-- <v-divider v-if="hover" width="500" style="margin: auto;"></v-divider> -->
                          <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                            <img src="../assets/socialApps.svg" alt="social apps" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '152px' : '100px' }" >
                          </v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                          <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >تطبيقات توصيل الطلبات</p>
                            <p class="arabic mt-5 mb-7 text-center"  v-if="isHover || hover" style="font-size: medium; align-self: center; transition: all .3s normal linear; color: #005f9e;">تطبيقات توصيل طلبات مثل :</p>
                            <v-row align-content="center" justify="center" v-if="hover" class="pa-0 ma-0" >
                              <img class="mx-5" width="32" src="../assets/marsool.svg" />
                              <img class="mx-8" width="40" src="../assets/hungerstasion.svg" />
                              <img class="mx-5" width="32" src="../assets/toyou.svg" />
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic text-center" :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;" ><span style="display: none;">تطبيق مثل</span> مرسول <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span>  هنقرستيشن  <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span>  تويو </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/delivery.svg" alt="delivery Apps" class=" rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" > تطبيقات المتاجر الكترونية</p>
                            <p class="arabic mt-5 mb-7 text-center"  v-if="isHover || hover" style="font-size: medium; align-self: center; transition: all .3s normal linear; color: #005f9e;">متاجر الكترونية مثل: </p>
                            <v-row align-content="center" justify="center" v-if="hover" class="pa-0 ma-0" >
                              <img class="mx-5" width="25" src="../assets/noon.svg" />
                              <img class="mx-2" width="45" src="../assets/namshi.svg" />
                              <img class="mx-3" width="40" src="../assets/shein.svg" />
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic text-center" :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style=" color: #005f9e; " ><span style="display: none;">تطبيق مثل</span> نون <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span>  نمشي  <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span>  شي ان </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/storeapp.svg" alt="Store App" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >تطبيقات الخدمات العامة</p>
                            <p class="arabic mt-5 mb-7 text-center"  v-if="isHover || hover" style="font-size: medium; align-self: center; transition: all .3s normal linear; color: #005f9e;">تطبيقات خدمات مثل :</p>
                            <v-row align-content="center" justify="center" v-if="hover" class="pa-0 ma-0" >
                              <img class="mx-5" width="40" src="../assets/uber.svg" />
                              <img class="mx-5" width="45" src="../assets/trivago.svg" />
                              <img class="mx-5" width="27" src="../assets/jareer.svg" />
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic mx-1 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}" style="color: #005f9e; " ><span style="display: none;">تطبيق مثل</span> اوبر <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span>  <span class="mx-2">تريفاغو</span>  <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span>  جرير </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/services.svg" alt="Sercives App" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '100px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-tab-item>
              <!--  مواقع الكترونية-->
              <v-tab-item >
                <v-row class="d-flex justify-center algin-center flex-row  mt-16" align-content="center" justify="center">
                  <v-col class="d-flex justify-center" md="5"  sm="12">
                    <v-hover v-slot="{hover}">
                    <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: all .3s normal linear; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                      <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center;">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" > مواقع أعمال</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;" >  موقع الكتروني احترافي </p>
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                            <p class="arabic text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">موقع الكتروني : لأعمالك <v-icon>mdi-circle-small</v-icon>  لشركتك </p>
                              </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/website-work.svg" alt="business website" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                          <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >متاجر الكترونية</p>
                            <p class="arabic mt-5 mb-7 text-center"  v-if="isHover || hover" style="font-size: medium; align-self: center; transition: all .3s normal linear; color: #005f9e;">مواقع متاجر الكترونية مثل:</p>
                            <v-row align-content="center" justify="center" v-if="hover" class="pa-0 ma-0" >
                              <img class="mx-5" width="32" src="../assets/amazon.svg" />
                              <img class="mx-8" width="60" src="../assets/mumzworld.svg" />
                              <img class="mx-5" width="32" src="../assets/bodyshop.svg" />
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic text-center" :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style=" color: #005f9e; " ><span style="display: none;">تطبيق مثل</span> امازون <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span>  ممزورلد  <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span>  ذا بودي شوب </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/website-store.svg" alt="sebsite store" class=" rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }"></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >مواقع السيرة الذاتية</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">نقدم لك مواقع سيرة ذاتية</p>
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic text-center" :style="{'font-size': $vuetify.breakpoint.smAndDown? '10px' : '15px'}"  style=" color: #005f9e; " >موقعك الخاص لتعرض أعمالك الشخصية</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/cv.svg" alt="CV" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '85px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;">مواقع مدونات</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                            <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">تطبيقات خدمات مثل :</p>
                            </v-row>
                            <v-row align-content="center" justify="center" :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;" class="" >
                              <p class="arabic text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e; " ><span style="display: none;">تطبيق مثل</span> اوبر <v-icon>mdi-circle-small</v-icon> <span style="display: none;">تطبيق مثل</span>  <span class="mx-2">تريفاغو</span>  <v-icon>mdi-circle-small</v-icon><span style="display: none;">تطبيق مثل</span>  جرير </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/blog.svg" alt="BLOG" class="rounded-0 ml-14" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '180px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-tab-item>
               <!-- لوحات التحكم -->
              <v-tab-item >
                <v-row class="d-flex justify-center algin-center flex-row  mt-16" align-content="center" justify="center">
                  <v-col class="d-flex justify-center" md="5"  sm="12">
                    <v-hover v-slot="{hover}">
                    <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: all .3s normal linear; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                      <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center;">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" > لوحات تحكم للمواقع </p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">لوحة تحكم لموقعك</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style=" color: #005f9e;">تتيح لك لوحات التحكم الذكية إدارة شاملة لموقعك</p>
                            </v-row>
                          </v-col>
                          <!-- <v-divider v-if="isHover || hover" width="500" style="margin: auto;"></v-divider> -->
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/dashboard_website.svg" alt="Dashboards" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                          <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >لوحات تحكم للتطبيقات </p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">لوحة تحكم للتطبيق</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">يمكنك من خلالها متابعة مستمرة لإحصائيات تطبيقك وإدارة شاملة له </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/dashboard_mobile.svg" alt="mobile App dashboard" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '100px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >لوحات ادارة اعمال</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">لوحة لمتابعة عملك </p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">يمكنك استخدامها لمتابعة الموظفين وأوقات دوامهم وأعمالهم</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/dashboard_business.svg" alt="business dashboards" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;">احصائيات دورية مباشرة</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">لوحة إحصائيات مباشرة </p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">تقديم احصائيات يومية او شهرية او سنوية لأي سوق تستهدفه لتحقيق فهم أفضل وأعمق لعملائك وأعمالك</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/dashboard_statics.svg" alt="statics" class="rounded-0 ml-14" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '180px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-tab-item>
               <!-- الاتمتة والذكاء -->
              <v-tab-item >
                <v-row class="d-flex justify-center algin-center flex-row  mt-16" align-content="center" justify="center">
                  <v-col class="d-flex justify-center" md="5"  sm="12">
                    <v-hover v-slot="{hover}">
                    <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: all .3s normal linear; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                      <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center;">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" > أتمتة الأعمال الالكترونية</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">أتمتة الاعمال على المواقع</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">توفر عليك المجهود والمال الكثير باعتبار أن آلياتنا ممكن أن تعوض عمل عدد كبير من الموظفين بالاضافة الى توحيد طريقة العمل وتلافي الأخطاء البشرية تماماً</p>
                            </v-row>
                          </v-col>
                          <!-- <v-divider v-if="isHover || hover" width="500" style="margin: auto;"></v-divider> -->
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/bot_automation.svg" alt="automation" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                          <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >بوتات الواتساب  لخدمة العملاء</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">أتمتة خدمة العملاء</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">آلياتنا قادرة على الرد على جميع استفسارات الزبائن المتعلقة بعملك على مدار الساعة</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/bot_whatsapp.svg" alt="whatsApp bot" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '110px' : '100px' }" ></v-col>
                    </v-card>
                  </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >بوتات التليغرام لخدمة العملاء</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">أتمتة خدمة العملاء</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">آلياتنا قادرة على الرد على جميع استفسارات الزبائن المتعلقة بعملك على مدار الساعة</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/bot_telegram.svg" alt="telegram bot" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                  <v-col class="d-flex justify-center" md="5" >
                    <v-hover v-slot="{ hover }">
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; text-align: center; transition: all .3s normal linear; color: #00609E;"> بوتات استخراج البيانات من المواقع</p>
                            <v-row align-content="center" justify="center" v-if="isHover || hover" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7"  v-if="isHover || hover" style="font-size: 15px; align-self: center; transition: all .3s normal linear; color: #005f9e;">أتمتة الاحصائيات اليومية</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap :style="{ 'opacity': hover || isHover ? '1' : '0' }" style=" transition: all .3s normal linear; opacity: 0;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">خدمة استخراج البيانات من المواقع هي عملية تقنية تهدف إلى جمع واستخلاص البيانات والمعلومات من مواقع الإنترنت بشكل آلي</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/bot.svg" alt="bot" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-tab-item>
               <!-- كتابة المحتوى-->
                <v-tab-item >
                <v-row class="d-flex justify-center algin-center flex-row  mt-16" align-content="center" justify="center">
                  <v-col class="d-flex justify-center" md="5"  sm="12">
                    <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: all .3s normal linear; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                      <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center;">
                            <!-- <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >خدمات SEO</p> -->
                            <v-row align-content="center" justify="center"  mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7 text-center" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #005f9e;"> خدمة تحسين SEO المواقع </p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap style=" transition: all .3s normal linear;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style=" color: #005f9e;">نقوم بدراسة وتحليل متطلبات ال SEO في موقعك وتقديم أفضل المعاملات لتحسين نتائج البحث</p>
                            </v-row>
                          </v-col>
                          <!-- <v-divider v-if="isHover || hover" width="500" style="margin: auto;"></v-divider> -->
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/keyWords.svg" alt="Dashboards" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-col>

                  <v-col class="d-flex justify-center" md="5" >
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                          <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <!-- <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >كتابة المحتوى</p> -->
                            <v-row align-content="center" justify="center" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7 text-center"  style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #005f9e;">  خدمات كتابة المحتوى</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap style=" transition: all .3s normal linear;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">    نقدم لك كافة خدمات كتابة المحتوى من كتابة وصف المنتجات ,كتابة مقالات وغيرها بالتوافق مع ال SEO </p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/typewriter.svg" alt="mobile App dashboard" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-col>

                  <v-col class="d-flex justify-center" md="5" >
                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <!-- <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;" >الإعلانات</p> -->
                            <v-row align-content="center" justify="center" mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7 text-center"  style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #005f9e;">الإعلانات الالكترونية لمشروعك</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap style=" transition: all .3s normal linear;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">تشمل خدماتنا استهداف الجمهور المناسب و خطط السوشيال ميديا الناجحة وأيضا Google Ads</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/blog.svg" alt="business dashboards" class="rounded-0 ml-10" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-col>

                  <v-col class="d-flex justify-center" md="5" >

                      <v-card :style="{'width': $vuetify.breakpoint.smAndDown? '325px':'600px'}" height="220" class="rounded-xxl d-flex justify-center flex-row algin-center" elevation="5" style="transition: opacity 10s; border-right: 5px solid #005f9e;
                               border-left: 5px solid #005f9e; box-shadow: #005f9e; border-radius: 25px;">
                        <v-col cols="8" class="d-flex flex-column algin-center justify-center" style="width: 450px; align-self: center; ">
                            <!-- <p class="arabic ma-5 mt-10" v-if="!hover && !isHover" style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #00609E;">خدمات الاستضافة</p> -->
                            <v-row align-content="center" justify="center"  mediums="pa-0 ma-0" >
                              <p class="arabic mt-5 mb-7 text-center"   style="font-size: larger; align-self: center; transition: all .3s normal linear; color: #005f9e;">خدمات الاستضافات والسيرفرات</p>
                            </v-row>
                            <v-row align-content="center" justify="center" wrap style=" transition: all .3s normal linear;"  class=""  >
                              <p class="arabic mx-8 text-center"  :style="{'font-size': $vuetify.breakpoint.smAndDown? '11px' : '15px'}"  style="color: #005f9e;">نوفر لك سيرفرات خاصة بمشروعك بجودة عالية وبما يناسب حجم أعمالك</p>
                            </v-row>
                          </v-col>
                        <v-col cols="4" style="display: flex;align-items: center;justify-content: center;">
                          <img src="../assets/servers service.svg" alt="statics" class="rounded-0 ml-14" :style="{'width' : $vuetify.breakpoint.mdAndUp ? '150px' : '100px' }" ></v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </div>
  </div>
</template>

<!-- لوحات تحكم: موافع - تطبيقات - إدارة الأعمال - إحصائيات دورية مباشرة-->
<!-- الأتمتة والذكاء الصنعي:  أتمتة الأعمال الالكترونية - بوتات تلغرام لخدمة العملاء - بوتات واتساب - بوتات إحصائيات-->

<script>
export default {
  data () {
    return {
      tab: null,
      hover: false,
      items: [
        { tab: 'تطبيقات الجوال', icon: 'mdi-cellphone' },
        { tab: ' مواقع الكترونية', icon: 'mdi-web' },
        { tab: 'لوحات التحكم', icon: 'mdi-monitor-dashboard' },
        { tab: 'الأتمتة والذكاء الصنعي', icon: 'mdi-robot-outline' },
        { tab: 'خدمات إضافية تقدمها راقي', icon: 'mdi-typewriter' }
      ]
    }
  },
  computed: {
    isHover () {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style>
.v-slide-group__next, .v-slide-group__prev {
  min-width: 25px !important;
}
.v-slide-group__prev.v-slide-group__prev--disabled{
  display: none !important;
}
.v-tabs-slider-wrapper{
  /* width: 10px !important; */
}
.theme--light.v-tabs .v-tab--active:focus::before {
    opacity: 0 !important;
    background-color: transparent;
}
.theme--light.v-tabs .v-tab:hover::before {
    opacity: 0;
}
.theme--light.v-tabs .v-tab--active:hover::before {
  opacity: 0 !important;
  background-color: transparent;
}
.theme--light.v-tabs .v-tab--active::before{
  opacity: 0 !important;
  background-color: transparent;
}
.v-tab {
    max-width: 100% !important;
}
.ourWork-bg{
    /* background-color: rgba(160, 184, 255, 0.103); */
    background-image: url(../assets/OurWork.jpg);

}
.v-window-item.v-window-item--active{
  margin-bottom: 50px;
}
</style>
