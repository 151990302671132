import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    articles: [],
    requestedService: ''
  },
  getters: {
    getArticles: (state) => state.articles
  },
  mutations: {
    SET_ARTICLES (state, articles) {
      state.articles = articles
    },
    set_requestedService (state, value) {
      state.requestedService = value
    }
  },
  actions: {
    async fetchArticles ({ commit }) {
      try {
        const data = await axios.get(
          'https://www.teebtop.com/dashboard/api-get-all-articles'
        )
        commit('SET_ARTICLES', data.data)
      } catch (error) {
        console.log(error)
      }
    },
    updaterequestedService ({ commit }, value) {
      commit('setrequestedService', value)
    }
    // fetchArticlesById ({ commit }, data) {
    //   this.getArticles(data.id)
    //     .then(function (response) {
    //       commit('SET_ARTICLES', response.data)
    //     })
    // }
  },
  modules: {
  }
})
