import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('../views/ContactUsView.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/PortfolioView.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/BlogView.vue')
  },
  {
    path: '/article/:headline',
    name: 'article',
    props: true,
    component: () => import('../views/ArticleView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../views/PrivacyPolicy.vue')
  },
  {
    path: '/apps-dev',
    name: 'apps-dev',
    component: () => import('../views/ApplicationsDevelopment.vue')
  },
  {
    path: '/online-stores',
    name: 'online-stores',
    component: () => import('../views/OnlineStores.vue')
  },
  {
    path: '/post/django',
    name: 'django',
    component: () => import('../views/DjangoView.vue')
  },
  {
    path: '/post/laravel',
    name: 'laravel',
    component: () => import('../views/LaravelView.vue')
  },
  {
    path: '/post/vue',
    name: 'vue',
    component: () => import('../views/VueView.vue')
  },
  {
    path: '/post/wordp',
    name: 'wordp',
    component: () => import('../views/WordPView.vue')
  },
  {
    path: '/automation-dev',
    name: 'automation-dev',
    component: () => import('../views/AutomationDev.vue')
  },
  {
    path: '/web-price-view',
    name: 'web-price-view',
    component: () => import('../views/WebDevPricePlans.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash) {
      scrollTo = to.hash
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  }
})

export default router
