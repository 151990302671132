<template>
  <div style="height: 350px;" data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000">
      <v-parallax
      dark
      src="../assets/mobile_dev1_copy.jpg"
      style="height: 350px !important;"
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          class="text-center"
          cols="12"
        >
        <div class="d-flex align-center justify-center">
            <img src="../assets/blue_frame.png" style="position: absolute;
                width: 200px;
                margin-top: 150px;" alt=""
                class="rotate-image">
        </div>
          <h1 class="gulzar-regular  mb-4" style="font-size: 2rem; z-index: 1;;">
              نسعى جاهدين لتقديم تجربة استثنائية
          </h1>
          <h4 class="gulzar-regular" style="color: #005CBC; font-size: 1rem; z-index: 1;">
              حيث الإبداع يلتقي بالاحترافية في كل تطبيق نقدمه
          </h4>
              <v-btn v-if="!$vuetify.breakpoint.smAndDown" outlined rounded color="#002046" class="mt-5 arabic white--text" href="/contact-us" style="letter-spacing: 0; opacity: 92%; font-size:small;">
            احصل على كل ما تحتاجه لتطوير تطبيقاتك ومواقعك مع لوحات التحكم الفعّالة
          </v-btn>
              <v-btn v-if="$vuetify.breakpoint.smAndDown" outlined rounded color="#002046" class="mt-5 arabic white--text" href="/contact-us" style="letter-spacing: 0; opacity: 92%; font-size:small;">
            احصل على ما كل تحتاجه لتطوير برمجيتك
              </v-btn>
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script>
export default {

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Kufam:ital,wght@0,400..900;1,400..900&display=swap');
.gulzar-regular {
  font-family: "Kufam", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.blue-bg{
    background-color: aliceblue;
}
.rotate-image {
  animation: rotate2 15s infinite linear;
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
